import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {GeneralTransfer} from '../../models/GeneralTransfer';
import {BASE_URL} from '../../models/constant';
import {CustomForm, CustomFormSubmission} from '../../models/CustomForm';


@Injectable({
  providedIn: 'root'
})
export class ClientCustomFormService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient ) {}


  async get( ItemId: number) {
    const resp = await this.http
        .get<CustomForm>(
            `${BASE_URL}api/ClientCustomForm/${ItemId}`, this.httpOptions).toPromise();
    return resp;
  }

  async submitForm( ItemObject: CustomFormSubmission) {
    const resp = await this.http
        .post<GeneralTransfer>(
            `${BASE_URL}api/ClientCustomForm/`, ItemObject, this.httpOptions).toPromise();
    return resp;
  }

}
