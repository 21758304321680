import { Injectable } from '@angular/core';
import {SystemLabels, SystemMessages} from '../models/systemresources';

@Injectable()
export class SystemMessagingService {
  
  private defualtLanguage = 'AR';
  readonly messages: SystemMessages[] = [
    { msgKey : 'MSG_OK_GENERAL', msgValue: 'تمت العملية بنجاح!'},
    { msgKey : 'MSG_OK', msgValue: 'تم الحفظ بنجاح!'},
    { msgKey : 'MSG_SEARCH_NOT_FOUND', msgValue: 'لم يتم العثور على كلمة البحث'},
    { msgKey : 'MSG_SEARCH_FOUND_SINGLE', msgValue: 'تم العثور على مدخل واحد'},
    { msgKey : 'MSG_SEARCH_FOUND_DOUBLE', msgValue: 'تم العثور على مدخلان'},
    { msgKey : 'MSG_SEARCH_FOUND_MULTIPLE', msgValue: 'تم العثور على <0> مدخلات'},
    { msgKey : 'MSG_SENT_MSG_OK', msgValue: 'تم الارسال بنجاح!'},
    { msgKey : 'MSG_OK_UPDATE', msgValue: 'تم التعديل بنجاح!'},
    { msgKey : 'MSG_GENERAL_DUPLICATE_ENTRY', msgValue: 'المدخل متشابه!'},
    { msgKey : 'MSG_GENERAL_ERROR', msgValue: 'حدث خطأ في العملية, الرجاء المحاولة مرة في وقت آخر'},
    { msgKey : 'MSG_APPOINTMENT_INSERT_ERROR', msgValue: 'حدث خطأ في العملية, الرجاء المحاولة مرة ثانية في وقت آخر'},
    { msgKey : 'MSG_APPOINTMENT_INSERT_PER_DAY_ERROR', msgValue: 'الرجاء اختيار تاريخ موعد مختلف لعدم توفر موعد في التاريخ الذي تم اختياره من قبلكم وحاول مرة ثانية'},




    { msgKey : 'MSG_DELETE_ERROR', msgValue: 'حدث خطأ في العملية, ممكن المدخل مستعمل في مكان اخر'},
    { msgKey : 'MSG_EMAIL_USED', msgValue: 'البريد الالكتروني مستعمل الرجاء اختيار بريد الكتروني غير مستعمل'},
    { msgKey : 'MSG_EMAIL_FORMAT_ERROR', msgValue: 'الرجاء التأكد من صحة البريد الالكتروني'},
    { msgKey : 'MSG_REGNO_USED', msgValue: 'رقم القيد مستعمل الرجاء ادخال رقم قيد غير مستعمل'},
    { msgKey : 'MSG_EMPLOYEE_REGNO_USED', msgValue: 'رقم الموطف مستعمل الرجاء ادخال رقم غير مستعمل'},
    { msgKey : 'MSG_USER_NOT_ALLOWED', msgValue: 'عذرا لايمكن تنفيذ العملية لعدم توفر الامكانية للمستعمل!'},

    { msgKey : 'MSG_REGISTRATION_ID_NOT_DIGITS', msgValue: 'اسم الدخول يجب ان يكون ارقام فقط'},
    { msgKey : 'MSG_Auth_Account_Not_Found_Error', msgValue: 'لم يتم العثور على اسم الدخول'},
    { msgKey : 'MSG_Auth_PASSWORD_SENT', msgValue: 'ام ارسال كلمة المرور الى العنوان الالكتروني بنجاح'},
    { msgKey : 'MSG_Auth_ERROR', msgValue: 'عذراُ. حدث خطأ في عملية الدخول'},

    { msgKey : 'MSG_Auth_Email_Sent_Error', msgValue: 'حدث خطأ عند أرسال كلمة المرور'},
    { msgKey : 'MSG_FILE_UPLOAD_MAX_Error', msgValue: 'حدث خطأ لايمكن تحميل ملف اكبر من 3 ميك'},
    { msgKey : 'MSG_SETTING_VALUE_USED_Error', msgValue: 'حدث خطأ الاسم مستعمل, الرجاء استعمال اسم مختلف'},




    { msgKey : 'MSG_LOCATION_ERROR_ADDING', msgValue: 'لايمكن اضافة منطقة جديدة الى ناجية'},
    { msgKey : 'MSG_LOCATION_ERROR_NODE_HAS_CHILDREN', msgValue: 'لايمكن الحذف لوجود مدخلات ضمن هذا المدخل'},
    { msgKey : 'MSG_LOCATION_ERROR_NODE_NO_UPDATE', msgValue: 'لايمكن تعديل هذا المدخل'},

    { msgKey : 'MSG_BOOKING_VALIDATION', msgValue: 'الرجاء ملىء الحقول المطلوبة'},


  
  ];
  
  readonly Labels: SystemLabels[] = [
    {pageId : 'LOGIN', labelName : 'BUTTON-LOGIN', labelTextEn : 'login', labelTextAR : 'دخول', labelTextKR : 'دخول'},
    {pageId : 'LOGIN', labelName : 'PLACEHOLDER-LOGIN_NAME', labelTextEn : 'Login Name', labelTextAR : 'اسم الدخول', labelTextKR : 'دخول'},
    {pageId : 'LOGIN', labelName : 'PLACEHOLDER-PASSWORD', labelTextEn : 'Password', labelTextAR : 'كلمة المرور', labelTextKR : 'دخول'},
    {pageId : 'LOGIN', labelName : 'CHECKBOX-MANAGEMENT', labelTextEn : 'Management', labelTextAR : 'إدارة', labelTextKR : 'دخول'},
    {pageId : 'LOGIN', labelName : 'CHECKBOX-STUDENT', labelTextEn : 'Student', labelTextAR : 'طالب', labelTextKR : 'دخول'},
    {pageId : 'LOGIN', labelName : 'TEXT-GO-TO-PASSWORD-RECOVERY', labelTextEn : 'Forgotten My Password', labelTextAR : ' لا اتذكر كلمة المرور', labelTextKR : 'دخول'},
    
    {pageId : 'PASSWORD-RECOVERY', labelName : 'BUTTON-SEND', labelTextEn : 'Send', labelTextAR : 'إرسل', labelTextKR : 'دخول'},
    {pageId : 'PASSWORD-RECOVERY', labelName : 'PLACEHOLDER-LOGIN_NAME', labelTextEn : 'Login Name', labelTextAR : 'اسم الدخول', labelTextKR : 'دخول'},
    {pageId : 'PASSWORD-RECOVERY', labelName : 'TEXT-GO-TO-LOGIN', labelTextEn : 'Login Page', labelTextAR : ' رجوع لصفحة الدخول', labelTextKR : 'دخول'},
  ];
  
  readonly ArabicWordTypes: any[] = [
    { typeName: 'اسم', typeNumber: '1' },
    { typeName: 'فعل', typeNumber: '2' },
    { typeName: 'صفة', typeNumber: '3' },
    { typeName: 'ظرف', typeNumber: '4' },
    { typeName: 'حرف - جرّ', typeNumber: '5' },
    { typeName: 'عبارة قبل اسم', typeNumber: '6' },
    { typeName: 'ضمير', typeNumber: '7' },
    { typeName: 'عبارة أو مصطلح', typeNumber: '8' },
    { typeName: 'الصيغ الفعلية', typeNumber: '9' },
    { typeName: 'اسم مذكر', typeNumber: 'A' },
    { typeName: 'اسم مؤنث', typeNumber: 'B' },
    { typeName: 'اسم دولة أو مكان', typeNumber: 'C' },
    { typeName: 'اسم استفهام', typeNumber: 'D' },
    { typeName: 'الأعداد', typeNumber: 'E' },
    { typeName: 'فعل شاذ', typeNumber: 'F'  }
  ];
  
  readonly EnglishWordTypes: any[] = [
    { typeName: 'Noun', typeNumber: '1' },
    { typeName: 'Verb ', typeNumber: '2' },
    { typeName: 'Adjective ', typeNumber: '3' },
    { typeName: 'Adverb ', typeNumber: '4' },
    { typeName: 'Preposition ', typeNumber: '5' },
    { typeName: 'Term before Noun', typeNumber: '6' },
    { typeName: 'pronoun ', typeNumber: '7' },
    { typeName: 'Term ', typeNumber: '8' },
    { typeName: 'Phras. Verb', typeNumber: '9' },
    { typeName: 'Male Name', typeNumber: 'A' },
    { typeName: 'Female Name', typeNumber: 'B' },
    { typeName: 'City & Place', typeNumber: 'C' },
    { typeName: 'That - Wh', typeNumber: 'D' },
    { typeName: 'Numbers', typeNumber: 'E' },
    { typeName: 'Irregular Verb', typeNumber: 'F'  }
  ];
  
  public setDefaultLanguage( langId: string ) {
    this.defualtLanguage = langId;
  }
  
  public getMessage( keyName: string ): string {
    
    const val = this.messages.find( x => x.msgKey === keyName );
    if ( val ) {
      return val.msgValue;
    }
    
    return '';
  }
  public getLabelText( pageId: string, keyName: string ): string {
    
    const val = this.Labels.find( x => x.pageId === pageId && x.labelName === keyName );
    if ( val ) {
      return val.labelTextAR;
    }
    return '';
  }
  
  public getArabicWordType( wordTypeNumber: number ): string {
    
    const val = this.ArabicWordTypes.find( x => x.typeNumber === wordTypeNumber );
    if ( val ) {
      return val.typeName;
    }
    return '';
  }
  public getEnglishWordType( wordTypeNumber: number ): string {
    
    const val = this.EnglishWordTypes.find( x => x.typeNumber === wordTypeNumber );
    if ( val ) {
      return val.typeName;
    }
    return '';
  }
  
}
