import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {GeneralTransfer, PaginationModel, PagingAndFilteringInfo} from '../models/GeneralTransfer';
import {BASE_URL} from '../models/constant';
import {Department} from '../models/Department';
import {Lookups} from '../models/settings';


@Injectable({
  providedIn: 'root'
})
export class LookupService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient ) {}

  async getList( ) {
    const resp = await this.http
        .get<Lookups[]>(
            `${BASE_URL}api/Lookup`, this.httpOptions).toPromise();
    return resp;
  }


  async getListByType( typeId: string  ) {
    const resp = await this.http
        .get<Lookups[]>(
            `${BASE_URL}api/Lookup/GetByType/${typeId}`, this.httpOptions).toPromise();
    return resp;
  }

  async get( ItemId: number) {
    const resp = await this.http
        .get<Lookups>(
            `${BASE_URL}api/Lookup/${ItemId}`, this.httpOptions).toPromise();
    return resp;
  }
  /*
  async profile( ) {
    const resp = await this.http
        .get<OperatorInformation>(
            `${BASE_URL}api/department/profile`, this.httpOptions).toPromise();
    return resp;
  }

   */

  async delete( ItemId: number) {
    const resp = await this.http
        .delete<GeneralTransfer>(
            `${BASE_URL}api/Lookup/${ItemId}`, this.httpOptions).toPromise();
    return resp;
  }


  async add( ItemObject: Lookups) {
    const resp = await this.http
        .post<GeneralTransfer>(
            `${BASE_URL}api/Lookup/`, ItemObject, this.httpOptions).toPromise();
    return resp;
  }


  async update( ItemObject: Lookups) {
    const resp = await this.http
        .put<GeneralTransfer>(
            `${BASE_URL}api/Lookup/`, ItemObject, this.httpOptions).toPromise();
    return resp;
  }

}
