import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {GeneralTransfer, PaginationModel, PagingAndFilteringInfo} from '../models/GeneralTransfer';
import {BASE_URL} from '../models/constant';
import {OperatorInformation} from '../models/OperatorInformation';


@Injectable({
  providedIn: 'root'
})
export class OperatorManagerService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient ) {}

  async getList( ) {
    const resp = await this.http
        .get<OperatorInformation[]>(
            `${BASE_URL}api/Operator`, this.httpOptions).toPromise();
    return resp;
  }

  async get( ItemId: number) {
    const resp = await this.http
        .get<OperatorInformation>(
            `${BASE_URL}api/Operator/${ItemId}`, this.httpOptions).toPromise();
    return resp;
  }
  async profile( ) {
    const resp = await this.http
        .get<OperatorInformation>(
            `${BASE_URL}api/Operator/profile`, this.httpOptions).toPromise();
    return resp;
  }

  async delete( ItemId: number) {
    const resp = await this.http
        .delete<GeneralTransfer>(
            `${BASE_URL}api/Operator/${ItemId}`, this.httpOptions).toPromise();
    return resp;
  }

  async add( ItemObject: OperatorInformation) {
    const resp = await this.http
        .post<GeneralTransfer>(
            `${BASE_URL}api/Operator/`, ItemObject, this.httpOptions).toPromise();
    return resp;
  }

  async update( ItemObject: OperatorInformation) {
    const resp = await this.http
        .put<GeneralTransfer>(
            `${BASE_URL}api/Operator/`, ItemObject, this.httpOptions).toPromise();
    return resp;
  }

  paging( paginginfo: PagingAndFilteringInfo) {

    return this.http.post<PaginationModel>( `${BASE_URL}api/Operator/paging`, paginginfo, this.httpOptions);
  }
}
