import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../core/authentication.service';
import {MessageService} from 'primeng/api';
import {SystemMessagingService} from '../../utilites/system-messaging.service';

@Component({
  selector: 'app-retrieve-password',
  templateUrl: './retrieve-password.component.html',
  styleUrls: ['./retrieve-password.component.scss'],
  providers: [MessageService]
})
export class RetrievePasswordComponent implements OnInit {

  userId = '';
  fakeText = ' ';
  constructor(private router: Router,
              private authApi: AuthenticationService,
              private messageService: MessageService,
              private msgService: SystemMessagingService) {
  }

  ngOnInit(): void {
  }

  async onSend() {

    await this.authApi.sendPassword( this.userId).then( res => {
    this.showMessage( res.isOK === true ? 'success' : 'error', this.msgService.getMessage( res.keyValue) );

    }).catch( error => {
      this.showMessage( 'error', this.msgService.getMessage( 'MSG_Auth_ERROR') );
    });
  }

  showMessage( severity: string, message: string ) {
    this.messageService.clear();
    this.messageService.add({severity, summary: '', detail: message});
  }
}
