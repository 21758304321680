
export class CustomForm {
    id: number;
    formName: string;
    emailAddress: string;
    isActive: boolean;
    configurationFields: string;
}

export class CustomFormSubmission {
    id: number;
    formId: number;
    submissionData: string;

}


export class ControlSelectionPlaceHolder {
    id: string;
    name: string;
    type: string;
    icon: string;
    selected: boolean;
}


export class Field {
    name: string;
    id: string;
    label: string;
    type: string;
    icon: string;
    value: any;
    required: boolean;
    selected: boolean;
}

export class TextBox extends Field {
    maxlength: number;
}
export class NumericBox  extends Field {
    min: number;
    max: number;
}

export class EmailBox  extends Field {
    validation: string;

}

export class TextAreaBox  extends Field {
    rows: number;
    maxlength: number;

}

export class CheckBox  extends Field {
    defaultValue: boolean;
}

export class DropdownBox  extends Field {
    options: DropdownOption[] = [];
}

export class DropdownOption {
    id: number;
    name: any;
    selected: boolean;
    editMode: boolean;
}

