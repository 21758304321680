import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Subject} from 'rxjs';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {UserInformation} from '../models/UserInformation';
import {GeneralTransfer} from '../models/GeneralTransfer';
import {BASE_URL} from '../models/constant';
import {CookieInformation} from '../models/CookieInformation';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  private isLoggedInAnnounced = new Subject<boolean>();
  isLoggedInAnnounced$ = this.isLoggedInAnnounced.asObservable();

  constructor(private http: HttpClient, private router: Router, private cookieService: CookieService ) { }

  announceLogin( isLoggedIn: boolean) {
    this.isLoggedInAnnounced.next(isLoggedIn);
  }

  signOut() {
    this.cookieService.delete('AMAApptSystem' );
    this.cookieService.delete('AppInfo' );
    this.router.navigate(['auth/login']);

  }
  async signIn(userInfo: UserInformation) {
    const resp = await this.http
        .post<GeneralTransfer>(
            `${BASE_URL}api/Authentication/signin`,
            userInfo,
            this.httpOptions
        )
        .toPromise();
    return resp;
  }
  
  async sendPassword(userId: string) {
    const resp = await this.http
        .get<GeneralTransfer>(
            `${BASE_URL}api/Authentication/sendpassword/${userId}`, this.httpOptions)
        .toPromise();
    return resp;
  }
  
  getUserType( ): string {

    if ( this.cookieService.check('AppInfo') === false ) {
      this.cookieService.delete('AMAApptSystem');
      this.cookieService.delete('AppInfo');
      this.router.navigateByUrl('auth/login');
      return '';
    }
    const info: CookieInformation = JSON.parse( this.cookieService.get('AppInfo') );
    return info.TypeId;
  }

  getUserEntityId( ): string {

    if ( this.cookieService.check('AppInfo') === false ) {
      this.cookieService.delete('AMAApptSystem');
      this.cookieService.delete('AppInfo');
      this.router.navigateByUrl('auth/login');
      return '';
    }
    const info: CookieInformation = JSON.parse( this.cookieService.get('AppInfo') );
    return info.RegId;
  }

  isLoggedIn(): boolean {

    const uInfo = this.cookieService.get('AppInfo');
    if ( uInfo == null || uInfo.length === 0 ) {
      this.isLoggedInAnnounced.next( false );
      return false;
    }
    this.isLoggedInAnnounced.next( true );
    return true;
  }
}
