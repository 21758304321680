import {AfterViewInit, Component, ElementRef, OnInit} from '@angular/core';
import {MessageService} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {Appointment} from './models/Appointment';
import {ClientAppointmentService} from './core/clientappointment.service';
import {PrintService} from './core/print.service';


@Component({
  selector: 'app-appointment-booking',
  templateUrl: './AppointmentBooking.component.html',
  providers: [MessageService]

})
export class AppointmentBookingComponent implements OnInit, AfterViewInit {


 appointmentModel: Appointment = new Appointment();

    cityName = 'مديرية بلديات محافظة البصرة';
    municipalityName  = 'بلدية سفوان';
    formTitle = 'نظام التقديم والحجز المسبق';


  constructor( private router: Router,
               private route: ActivatedRoute,
               private elementRef: ElementRef,
               private appointmentApiService: ClientAppointmentService,
               public printService: PrintService) { }

  ngAfterViewInit(){
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#344179';
    }
  ngOnInit(): void {

      this.route.params.subscribe(params => {

          this.appointmentModel.appointmentNo = params['apptNo']; // (+) converts string 'id' to a number
          this.getAppointment(  this.appointmentModel.appointmentNo );
      });
  }
  getAppointment( AppointmentNo: string ) {
      this.appointmentApiService.getAppointment( AppointmentNo ).then( qrCode => {
          this.appointmentModel = qrCode;
      });

  }



  onPrint() {

    const reportData = [this.appointmentModel.appointmentNo.toString()];
    this.printService.printDocument('booking', reportData);
  }


    onNew() {
        this.router.navigateByUrl('/' );
    }
}
