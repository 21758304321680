import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class RouteGuardService  implements CanActivate {

  constructor(private router: Router, private cookieService: CookieService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url: string = state.url;
    console.log('allowedRoles: ' + route.data.allowedRoles);
    return this.checkLogin(route, url);
  }

  checkLogin(route: ActivatedRouteSnapshot, url: string): boolean {
    if (this.isLoggedIn() === false) {
      this.router.navigate(['auth/login']);
      return false;
    }
    return true;
  }

  isLoggedIn(): boolean {

    const uInfo = this.cookieService.get('AMAApptSystem');
    if (uInfo == null || uInfo.length === 0) {
      return false;
    }
    return true;
  }
}
