import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CaptionPipe} from './caption-pipe/caption-pipe.component';
import {SystemMessagingService} from './system-messaging.service';



@NgModule({
  declarations: [CaptionPipe],
  imports: [
    CommonModule
  ],
  providers: [
    CaptionPipe,
    SystemMessagingService
  ],
  exports: [CaptionPipe]
})
export class UtilitesModule { }
