import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {GeneralTransfer} from '../models/GeneralTransfer';
import {BASE_URL} from '../models/constant';
import {Settings} from '../models/settings';


@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient ) {}



  async get( ItemId: string) {
    const resp = await this.http
        .get<any>(
            `${BASE_URL}api/setting/${ItemId}`, this.httpOptions).toPromise();
    return resp;
  }

  async delete( ItemId: number) {
    const resp = await this.http
        .delete<GeneralTransfer>(
            `${BASE_URL}api/setting/${ItemId}`, this.httpOptions).toPromise();
    return resp;
  }

  async add( ItemObject: Settings) {
    const resp = await this.http
        .post<GeneralTransfer>(
            `${BASE_URL}api/setting`, ItemObject, this.httpOptions).toPromise();
    return resp;
  }

  async update( ItemObject: Settings) {
    const resp = await this.http
        .put<GeneralTransfer>(
            `${BASE_URL}api/setting`, ItemObject, this.httpOptions).toPromise();
    return resp;
  }






}
