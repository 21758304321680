import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {GeneralTransfer, PaginationModel, PagingAndFilteringInfo} from '../models/GeneralTransfer';
import {BASE_URL} from '../models/constant';
import {Department} from '../models/Department';
import {Place} from '../models/Places';


@Injectable({
  providedIn: 'root'
})
export class LocationService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient ) {}

  async getFullList( ) {
    const resp = await this.http
        .get<Place[]>(
            `${BASE_URL}api/Location/list`, this.httpOptions).toPromise();
    return resp;
  }
  async getTree( ) {
    const resp = await this.http
        .get<any>(
            `${BASE_URL}api/Location`, this.httpOptions).toPromise();
    return resp;
  }

  async get( ItemId: number) {
    const resp = await this.http
        .get<Place>(
            `${BASE_URL}api/Location/${ItemId}`, this.httpOptions).toPromise();
    return resp;
  }

  async getList( ItemId: number) {
    const resp = await this.http
        .get<Place[]>(
            `${BASE_URL}api/Location/${ItemId}`, this.httpOptions).toPromise();
    return resp;
  }


  /*
  async profile( ) {
    const resp = await this.http
        .get<OperatorInformation>(
            `${BASE_URL}api/department/profile`, this.httpOptions).toPromise();
    return resp;
  }

   */

  async delete( ItemId: number) {
    const resp = await this.http
        .delete<GeneralTransfer>(
            `${BASE_URL}api/Location/${ItemId}`, this.httpOptions).toPromise();
    return resp;
  }


  async add( ItemObject: Place) {
    const resp = await this.http
        .post<GeneralTransfer>(
            `${BASE_URL}api/Location/`, ItemObject, this.httpOptions).toPromise();
    return resp;
  }


  async update( ItemObject: Place) {
    const resp = await this.http
        .put<GeneralTransfer>(
            `${BASE_URL}api/Location/`, ItemObject, this.httpOptions).toPromise();
    return resp;
  }

}
