import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from '../../core/authentication.service';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {MessageService} from 'primeng/api';
import {SystemMessagingService} from '../../utilites/system-messaging.service';
import {UserInformation} from '../../models/UserInformation';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
  providers: [MessageService]
})
export class SigninComponent implements OnInit {

  fakeText = ' ';
  userInfo: UserInformation;
  constructor(private router: Router, private authApi: AuthenticationService,
              private cookieService: CookieService,
              private messageService: MessageService, private msgService: SystemMessagingService) {
    this.userInfo = new UserInformation();
    this.userInfo.registrationId = '';
    this.userInfo.password = '';
  }

  ngOnInit(): void {

    this.userInfo.rememberMe = false;
    const tmp = localStorage.getItem( 'userInfo');
    if ( tmp ) {
      this.userInfo = JSON.parse( tmp );
      if ( this.userInfo.rememberMe === false ) {
        this.userInfo = new UserInformation();
      }
    }
  }
  async  onSignIn() {

    await this.authApi.signIn( this.userInfo).then( res => {
      if ( this.userInfo.rememberMe === true ) {
        localStorage.setItem('userInfo', JSON.stringify(this.userInfo));
      } else {
        localStorage.removeItem('userInfo');
      }
      this.router.navigate(['/admin/home']);

    }).catch( error => {
      this.showMessage( 'error', this.msgService.getMessage( 'MSG_Auth_ERROR') );
    });

    }
  showMessage( severity: string, message: string ) {
    this.messageService.clear();
    this.messageService.add({severity, summary: '', detail: message});
  }
}
