import {AfterViewInit, Component, ElementRef, NgZone, OnInit, ViewChild} from '@angular/core';
import {MessageService, SelectItem, TreeNode} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {Department} from './models/Department';
import {Appointment} from './models/Appointment';
import {SystemMessagingService} from './utilites/system-messaging.service';
import {LOOKUP_APPLICATION_TYPE, LOOKUP_CITIZEN_TYPE} from './models/constant';
import {ClientAppointmentService} from './core/clientappointment.service';
import {PrintService} from './core/print.service';
import {Calendar} from 'primeng/calendar';


@Component({
  selector: 'app-appointment',
  templateUrl: './Appointment.component.html',
  providers: [MessageService]

})
export class AppointmentComponent implements OnInit, AfterViewInit  {


  @ViewChild('apptCalender', { static: true }) apptCalenderobject: Calendar;
  en: any;
  departments: Department[] = [];
  model: Appointment = new Appointment();
  appointmentModel: Appointment = new Appointment();

  cityName = 'مديرية بلديات محافظة البصرة';
  municipalityName  = 'بلدية سفوان';
  formTitle = 'نظام التقديم والحجز المسبق';
  birthCertificateNo1 = '';
  birthCertificateNo2 = '';
  birthCertificateNo3 = '';
  birthCertificateNo4 = '';
  steps = 1;
  disabledWeekDays: Array<number>;
  disabledDates: Array<Date>;
  minDate = new Date( Date.now() );
  maxDate = new Date( Date.now() );
  locationRootNode: TreeNode;

  podCities: SelectItem[] = [];
  podDistricts: SelectItem[] = [];
  podTowns: SelectItem[] = [];

  residenceCities: SelectItem[] = [];
  residenceDistricts: SelectItem[] = [];
  residenceTowns: SelectItem[] = [];

  employmentCities: SelectItem[] = [];
  employmentDistricts: SelectItem[] = [];
  employmentTowns: SelectItem[] = [];

  partnerResidenceCities: SelectItem[] = [];
  partnerResidenceDistricts: SelectItem[] = [];
  partnerResidenceTowns: SelectItem[] = [];

  landPlotCities: SelectItem[] = [];
  landPlotDistricts: SelectItem[] = [];
  landPlotTowns: SelectItem[] = [];

  citizenType: SelectItem[] = [];
  applicationType: SelectItem[] = [];

  maritalStatus: SelectItem[] = [ {label: 'متزوج(ة)' , value: 'M'},
                                  {label: 'اعزب / عزباء' , value: 'S'},
    {label: 'مطلق(ة)' , value: 'D'},
    {label: 'ارمل(ة)' , value: 'W'},
  ];
  genders: SelectItem[] = [{label: 'ذكر' , value: 'M'}, {label: 'انثى' , value: 'F'}];
  NationalNo = true;
  NationalCertificate = false;
  loading = false;

  constructor( private router: Router,
               private route: ActivatedRoute,
               private ngZone: NgZone,
               private elementRef: ElementRef,
               private appointmentApiService: ClientAppointmentService,
               private messageService: MessageService,
               public printService: PrintService,
               private msgService: SystemMessagingService) { }


  ngAfterViewInit(){
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#344179';
  }

  ngOnInit(): void {

    this.initializeModel();
   // this.disabledWeekDays.push( 5 );
    this.configureDepartmentAppointment( 1 );
    this.setLocale();
    this.getLookups();
    this.getLocationTree();


   /* this.appointmentApiService.getDepartmentList( ).then( result => {

      this.departments = result.filter( it => it.isDisabled === false );
      this.departments.forEach(item => {
        item.isSelected = false;
      });
    });*/

  }
  initializeModel() {

    this.model.id = 0;
    this.model.citizenName = '';
    this.model.telNo = '';
    // this.model.dob

    this.model.pobCity = 0;
    this.model.pobDistrict = 0;
    this.model.pobLocality = 0;

    this.model.residenceCity = 0;
    this.model.residenceDistrict = 0;
    this.model.residenceLocality = 0;


    this.model.employmentCity = 0;
    this.model.employmentDistrict = 0;
    this.model.employmentLocality = 0;



    this.model.nationalIDNo = '';
    this.model.birthCertificateDetails = '';
    this.model.birthCertificate2Details = '';

    this.model.employmentLength = 0;

    this.model.gender = '';
    this.model.maritalStatus = '';
    this.model.noOfChildren = 0;

    this.model.partnerName = '';
    this.model.partnerDOB = new Date();


    this.model.partnerResidenceCity = 0;
    this.model.partnerResidenceDistrict = 0;
    this.model.partnerResidenceLocality = 0;


    this.model.motherName = '';
    this.model.partnerMotherName = '';

    this.model.citizenCategoryId = 0;
    this.model.applicationTypeId = 0;

    this.model.landPlotNo = '';
    this.model.landDistrictNo = '';
    this.model.landPlotArea = 0;

    this.model.landPlotCity = 0;
    this.model.landPlotDistrict = 0;
    this.model.landPlotLocality = 0;


    this.model.emailAddress = '';
    this.model.notes = '';
    // this.model.apptDate: Date;
    this.model.apptDateString = '';



  }

  onNext() {

    if ( this.steps === 3  ) {
      return;
    }


    if ( this.validateStep( this.steps ) === false ) {
      return;
    }
    this.steps++;
    this.scrollFormToTop();
  }

  onPrev() {
    if ( this.steps === 1  ) {
      return;
    }
    this.steps--;
    this.scrollFormToTop();
  }

  validateStep( stepId: number ) {
    let result = true;
    let message = '';

    switch ( stepId){
      case 1: {
        if ( this.model.citizenName.trim().length === 0 ) {
          result = false;
        }
        if ( this.model.telNo.trim().length === 0 ) {
          result = false;
        }
        if ( this.model.gender === '') {
          result = false;
        }
        if ( this.model.dob == undefined ) {
          result = false;
        }
        if ( this.model.residenceCity < 1 ) // || this.model.residenceDistrict < 1 || this.model.residenceLocality < 1  )
        {
          result = false;
        }
        if ( this.NationalNo === true && this.model.nationalIDNo.trim().length === 0 ) {
          result = false;
        }

        if ( this.model.maritalStatus === '') {
          result = false;
        }

        if ( this.model.partnerName.trim().length === 0 ) {
          result = false;
        }
        if ( this.model.citizenCategoryId < 1 ) {
          result = false;
        }

        if ( this.model.emailAddress.length > 0 && this.validateEmail( this.model.emailAddress ) === false ) {
          message = 'MSG_EMAIL_FORMAT_ERROR';
          result = false;
        }

        break;
      }
      case 2: {

        if ( this.model.apptDate === undefined) {
          result = false;
        } else {
          if (this.checkSelectedDate(this.model.apptDate)) {
            message = 'MSG_APPOINTMENT_INSERT_PER_DAY_ERROR';
            result = false;
          }
        }
        break;
      }
    }
    if ( result === false) {
      message = message.length === 0 ? 'MSG_BOOKING_VALIDATION' : message;
      this.showMessage('error', this.msgService.getMessage(message));
    }
    return result;

  }

  validateEmail(email){
    const re = /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/;
    return re.test(email);
  }

  scrollFormToTop() {

    const scrollToTop = window.setInterval(() => {
    const pos = window.pageYOffset;
    if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
    } else {
        window.clearInterval(scrollToTop);
    }
    }, 16);
  }
  onConfirm() {

    this.loading = true;
    this.model.departmentId = 1;
    this.model.apptDate = new Date( this.model.apptDate);
    this.model.apptDateString = this.model.apptDate.toDateString();
    this.model.dob = new Date( this.model.dob);
    this.model.partnerDOB = new Date( this.model.partnerDOB);
    this.model.birthCertificateDetails = `${this.birthCertificateNo1} ## ${this.birthCertificateNo2}##${this.birthCertificateNo3}##${this.birthCertificateNo4}`;

    this.appointmentApiService.add( this.model ).then( result => {
      if ( result.isOK === false ) {
        this.loading = false;
        this.showMessage('error', this.msgService.getMessage(result.message));
      } else {
        this.model.appointmentNo = result.message;
        this.router.navigateByUrl('/appt/' + this.model.appointmentNo );
      }
     // this.steps++;
    });

  }

  onDepSelected( dep: Department) {

    this.departments.forEach(item => {
      item.isSelected = false;
    });
    dep.isSelected = true;

  }


  configureDepartmentAppointment( depId: number ) {

    this.disabledDates = [];
    this.disabledWeekDays = [];
    this.appointmentApiService.getForDepartment( depId ).then( result => {
      this.ngZone.run( () => {
        result.blockedDatesList.forEach( item => {
          this.disabledDates.push( new Date( item ) );
        });

        result.blockedDayOfWeek.forEach( day => {
          this.disabledWeekDays.push( day );
        });

        // this.disabledWeekDays.push( result.blockedDayOfWeek );
        this.maxDate = this.addDays( this.minDate, result.allowedBookingPeriod);

      });
    });

  }
  addDays(date, days) {
    const copy = new Date(Number(date));
    copy.setDate(date.getDate() + days);
    return copy;
  }
  setLocale() {
    this.en = {
      firstDayOfWeek: 0,
      dayNames: ["الاحد", "الاثنين", "الثلائاء", "الاربعاء", "الخميس", "الجمعة", "السبت"],
      dayNamesShort: ["الاحد", "الاثنين", "الثلائاء", "الاربعاء", "الخميس", "الجمعة", "السبت"],
      dayNamesMin: ["الاحد", "الاثنين", "الثلائاء", "الاربعاء", "الخميس", "الجمعة", "السبت"],
      monthNames: [ "كانون الثاني","شباط","اذار","نيسان","ايار","حزيران","تموز","اب","ايلول","تشرين الاول","تشرين الثاني","كانون الاول" ],
      monthNamesShort: [ "كانون الثاني","شباط","اذار","نيسان","ايار","حزيران","تموز","اب","ايلول","تشرين الاول","تشرين الثاني","كانون الاول" ],
      today: 'Today',
      clear: 'Clear',
      dateFormat: 'mm/dd/yy',
      weekHeader: 'Wk'
    };


  }

  getLocationTree(){

    this.appointmentApiService.getPlacesTree().then( result => {

      this.locationRootNode = result;

      this.locationRootNode.children.forEach( child => {
        this.podCities.push({label: child.label, value: child.key });
        this.residenceCities.push({label: child.label, value: child.key });
        this.employmentCities.push({label: child.label, value: child.key });
        this.partnerResidenceCities.push({label: child.label, value: child.key });
        this.landPlotCities.push({label: child.label, value: child.key });

      });
    });
  }

  getLookups() {

    this.appointmentApiService.GetLookupList().then( result => {

      result.forEach( item => {
        if ( item.keyType === LOOKUP_CITIZEN_TYPE ) {
          this.citizenType.push({ label : item.keyName, value : item.id});
        }

      });
    });
  }

  getLabelName( keyType: string, keyId: number): string {

    if ( keyType === LOOKUP_CITIZEN_TYPE ) {
      return this.citizenType.find(it => it.value === keyId).label;
    }
    if ( keyType === LOOKUP_APPLICATION_TYPE ) {
      return this.applicationType.find(it => it.value === keyId).label;
    }
  }

  onPrint() {

    const reportData = [this.model.id.toString()];
    this.printService.printDocument('booking', reportData);
  }

  onCityChange($event: any, placeType: string) {

    switch ( placeType ){
      case 'podCity': {
        const city = this.locationRootNode.children.find( child => child.key === $event.value);
        this.podDistricts = [];
        city.children.forEach( item => {
          this.podDistricts.push({label: item.label, value: item.key});
        });
        break;
      }
      case 'residenceCity': {
        const city = this.locationRootNode.children.find( child => child.key === $event.value);
        this.residenceDistricts = [];
        city.children.forEach( item => {
          this.residenceDistricts.push({label: item.label, value: item.key});
        });
        break;
      }
      case 'employmentCity': {
        const city = this.locationRootNode.children.find( child => child.key === $event.value);
        this.employmentDistricts = [];
        city.children.forEach( item => {
          this.employmentDistricts.push({label: item.label, value: item.key});
        });
        break;
      }
      case 'partnerResidenceCity': {
        const city = this.locationRootNode.children.find( child => child.key === $event.value);
        this.partnerResidenceDistricts = [];
        city.children.forEach( item => {
          this.partnerResidenceDistricts.push({label: item.label, value: item.key});
        });
        break;
      }
      case 'landPlotCity': {
        const city = this.locationRootNode.children.find( child => child.key === $event.value);
        this.landPlotDistricts = [];
        city.children.forEach( item => {
          this.landPlotDistricts.push({label: item.label, value: item.key});
        });
        break;
      }
    }

  }

  onDistrictChange($event: any, placeType: string) {

    switch ( placeType ){
      case 'pobDistrict': {
        const city = this.locationRootNode.children.find( child => child.key.toString() === this.model.pobCity.toString());
        const district = city.children.find( child => child.key === $event.value);
        this.podTowns = [];
        district.children.forEach( item => {
          this.podTowns.push({label: item.label, value: item.key});
        });
        break;
      }
      case 'residenceDistrict': {
        const city = this.locationRootNode.children.find( child => child.key.toString() === this.model.residenceCity.toString());
        const district = city.children.find( child => child.key === $event.value);
        this.residenceTowns = [];
        district.children.forEach( item => {
          this.residenceTowns.push({label: item.label, value: item.key});
        });
        break;
      }
      case 'employmentDistrict': {
        const city = this.locationRootNode.children.find( child => child.key.toString() === this.model.employmentCity.toString());
        const district = city.children.find( child => child.key === $event.value);
        this.employmentTowns = [];
        district.children.forEach( item => {
          this.employmentTowns.push({label: item.label, value: item.key});
        });
        break;
      }
      case 'partnerResidenceDistrict': {
        const city = this.locationRootNode.children.find( child => child.key.toString() === this.model.partnerResidenceCity.toString());
        const district = city.children.find( child => child.key === $event.value);
        this.partnerResidenceTowns = [];
        district.children.forEach( item => {
          this.partnerResidenceTowns.push({label: item.label, value: item.key});
        });
        break;
      }
      case 'landPlotDistrict': {
        const city = this.locationRootNode.children.find( child => child.key.toString() === this.model.landPlotCity.toString());
        const district = city.children.find( child => child.key === $event.value);
        this.landPlotTowns = [];
        district.children.forEach( item => {
          this.landPlotTowns.push({label: item.label, value: item.key});
        });
        break;
      }
    }
  }

  getLocationFullName( type: string) {

    let fullLocation = '';
    switch ( type ){
      case 'pod': {
        fullLocation += this.podCities.find( item => item.value == this.model.pobCity ).label;
        fullLocation += ' - ';
        fullLocation += this.podDistricts.find( item => item.value == this.model.pobDistrict ).label;
        fullLocation += ' - ';
        fullLocation += this.podTowns.find( item => item.value == this.model.pobLocality).label;
        break;
      }
      case 'residence': {
        fullLocation += this.residenceCities.find( item => item.value == this.model.residenceCity ).label;
       // fullLocation += ' - ';
       // fullLocation += this.residenceDistricts.find( item => item.value == this.model.residenceDistrict ).label;
       // fullLocation += ' - ';
       // fullLocation += this.residenceTowns.find( item => item.value == this.model.residenceLocality).label;
        break;
      }
      case 'employment': {
        fullLocation += this.employmentCities.find( item => item.value == this.model.employmentCity ).label;
        fullLocation += ' - ';
        fullLocation += this.employmentDistricts.find( item => item.value == this.model.employmentDistrict ).label;
        fullLocation += ' - ';
        fullLocation += this.employmentTowns.find( item => item.value == this.model.employmentLocality).label;
        break;
      }
      case 'landPlot': {
        const lplot = this.landPlotCities.find( item => item.value == this.model.landPlotCity );
        if ( lplot ) {
          fullLocation += lplot.label;
          fullLocation += ' - ';
          fullLocation += this.landPlotDistricts.find( item => item.value == this.model.landPlotDistrict ).label;
          fullLocation += ' - ';
          fullLocation += this.landPlotTowns.find( item => item.value == this.model.landPlotLocality).label;
        }
        break;
      }
      case 'partnerResidence': {
        const lplot = this.partnerResidenceCities.find( item => item.value == this.model.partnerResidenceCity );
        if ( lplot ) {
          fullLocation += lplot.label;
          fullLocation += ' - ';
          const district = this.partnerResidenceDistricts.find( item => item.value == this.model.partnerResidenceDistrict );
          if ( district ) {
            fullLocation += ' - ' + district.label;
            const town = this.partnerResidenceTowns.find( item => item.value == this.model.partnerResidenceLocality);
            if ( town ) {
              fullLocation += ' - ' + town.label;
            }
          }
        }
        break;
      }
    }
    return fullLocation;
  }
  getCertificateFullString( partNo: number) {

    if ( this.NationalCertificate === true ) {
      return `${this.birthCertificateNo1} - ${this.birthCertificateNo2} - ${this.birthCertificateNo3}`;
    }
    return '';

  }
  showMessage( severity: string, message: string ) {
    this.messageService.clear();
    this.messageService.add({ severity, summary: '', detail: message});
  }


  onEmploymentLengthKeyDown($event: KeyboardEvent) {

    if ( this.model.employmentLength < 0 )
      this.model.employmentLength = 0;

  }
  onOfChildrenKeyDown($event: KeyboardEvent) {

    if ( this.model.noOfChildren < 0 )
      this.model.noOfChildren = 0;

  }

  ondateselected( $event: Date ) {

    const val = $event.valueOf();
    const found = this.checkSelectedDate( $event );
    if ( found ) {
      this.showMessage('error', this.msgService.getMessage('MSG_APPOINTMENT_INSERT_PER_DAY_ERROR'));
    }
  }

  checkSelectedDate( selDate: Date ): boolean{
    let found = false;
    const val = selDate.valueOf();
    let i = 0;
    this.ngZone.run( () => {
      for ( i = 0; i <  this.disabledDates.length; i++ ) {
        if ( val === this.disabledDates[i].valueOf() ) {
          found = true;
          break;
        }
      }
    });

    return found;
  }
}
