import { NgModule } from '@angular/core';
import {RouteGuardService} from './route-guard.service';
import {AuthenticationService} from './authentication.service';
import {OperatorManagerService} from './OperatorManagerService';
import {DepartmentService} from './department.service';
import {SettingsService} from './Setting.Service';
import {AppointmentService} from './appointment.service';
import {LookupService} from './lookup.service';
import {LocationService} from './location.service';
import {FormDesignerService} from './custom-form/form-designer.service';
import {ClientCustomFormService} from './custom-form/client-custom-form.service';


@NgModule({
  declarations: [],
  providers: [
    RouteGuardService,
    AuthenticationService,
    OperatorManagerService,
    DepartmentService,
    AppointmentService,
    SettingsService,
    LookupService,
    LocationService,
    FormDesignerService,
    ClientCustomFormService
  ]
})
export class CoreModule { }
