export class Appointment {
    id: number;
    citizenName: string;
    appointmentNo: string;
    telNo: string;
    dob: Date;

    pobCity: number;
    pobDistrict: number;
    pobLocality: number;

    residenceCity: number;
    residenceDistrict: number;
    residenceLocality: number;


    employmentCity: number;
    employmentDistrict: number;
    employmentLocality: number;



    nationalIDNo: string;
    birthCertificateDetails: string;
    birthCertificate2Details: string;

    employmentLength: number;

    gender: string;
    maritalStatus: string;
    noOfChildren: number;

    partnerName: string;
    partnerDOB: Date;


    partnerResidenceCity: number;
    partnerResidenceDistrict: number;
    partnerResidenceLocality: number;


    motherName: string;
    partnerMotherName: string;

    citizenCategoryId: number;
    applicationTypeId: number;
    departmentId: number;

    citizenCategoryName: string;
    applicationTypeName: string;


    landPlotNo: string;
    landDistrictNo: string;
    landPlotArea: number;

    landPlotCity: number;
    landPlotDistrict: number;
    landPlotLocality: number;


    emailAddress: string;
    notes: string;
    apptDate: Date;
    apptDateString: string;
    qrCodeImage: string;
    updatedDate: Date;
}
