import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SigninComponent } from './signin/signin.component';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {PrimeNGModule} from '../primeng.module';
import {CoreModule} from '../core/core.module';
import {UtilitesModule} from '../utilites/utilites.module';
import { RetrievePasswordComponent } from './retrieve-password/retrieve-password.component';


const routes: Routes = [
  { path: 'login', component: SigninComponent },
  { path: 'retrieve-password', component: RetrievePasswordComponent },
];


@NgModule({
  declarations: [SigninComponent, RetrievePasswordComponent],
  imports: [
    CommonModule,
    FormsModule,
    PrimeNGModule,
    CoreModule,
    UtilitesModule,
    RouterModule.forChild(routes)
    
  ]
})
export class AuthenticationModule { }
