import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AppMainComponent } from './app.main.component';
import {AuthenticationService} from './core/authentication.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
    animations: [
        trigger('inline', [
            state('hidden', style({
                height: '0px',
                overflow: 'hidden'
            })),
            state('visible', style({
                height: '*',
            })),
            state('hiddenAnimated', style({
                height: '0px',
                overflow: 'hidden'
            })),
            state('visibleAnimated', style({
                height: '*',
            })),
            transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class AppMenuComponent implements OnInit {

    model: any[];

    inlineModel: any[];

    constructor(public app: AppMainComponent, private router: Router, private authApi: AuthenticationService) { }

    ngOnInit() {
        if ( this.authApi.isLoggedIn() === false ) {
            this.router.navigate(['/login']);
        }
        if ( this.authApi.getUserType() === '1' ) {
            this.model = [
                {label: 'الرئيسية', icon: 'pi pi-home', routerLink: ['/admin/home']},
                {
                    label: 'المستفيدين', icon: 'pi pi-plus',
                    items: [
                        {label: 'القائمة', icon: 'pi pi-list', routerLink: ['/admin/appointment/']}
                      //  {label: 'إضافة', icon: 'pi pi-pencil', routerLink: ['/appointment/add']},
                    ]
                },
                /*
                {
                    label: 'اقسام الدائرة', icon: 'pi pi-plus',
                    items: [
                        {label: 'القائمة', icon: 'pi pi-list', routerLink: ['/department/']},
                        {label: 'إضافة', icon: 'pi pi-pencil', routerLink: ['/department/add']},
                    ]
                },

                 */

                {
                    label: 'المستعملين', icon: 'pi pi-users',
                    items: [
                        {label: 'القائمة', icon: 'pi pi-list', routerLink: ['/admin/operator/']},
                        {label: 'إضافة', icon: 'pi pi-user-plus', routerLink: ['/admin/operator/add']},
                    ]
                },
/*
                {
                    label: 'الجداول', icon: 'pi pi-plus',
                    items: [
                        {label: 'القائمة', icon: 'pi pi-list', routerLink: ['/admin/custom-form/']},
                        {label: 'إضافة', icon: 'pi pi-pencil', routerLink: ['/admin/custom-form/add']},
                    ]
                },*/
                {
                    label: 'اعدادات', icon: 'pi pi-plus',
                    items: [
                       /* {label: 'المستعملين', icon: 'pi pi-users',
                            items: [
                                {label: 'القائمة', icon: 'pi pi-list', routerLink: ['/admin/operator/']},
                                {label: 'إضافة', icon: 'pi pi-user-plus', routerLink: ['/admin/operator/add']},
                            ]
                        }, */
                        {label: 'اعدادات بريد النظام', icon: 'pi pi-cog', routerLink: ['/admin/settings/email-info/']},
                        {label: 'اعدادات الحجوزات', icon: 'pi pi-cog', routerLink: ['/admin/settings/appt-config/']},
                        {label: 'اعدادات القوالب', icon: 'pi pi-cog', routerLink: ['/admin/settings/email-template/']},
                        {label: 'اعدادات القوائم', icon: 'pi pi-cog', routerLink: ['/admin/settings/lists/']},
                        {label: 'اعدادات المدن', icon: 'pi pi-cog', routerLink: ['/admin/settings/locations/']}
                    ]
                },
            ];
        }
        if ( this.authApi.getUserType() === '2' ) {
            this.model = [
                {label: 'الرئيسية', icon: 'pi pi-home', routerLink: ['/admin/home']},
                {
                    label: 'المستفيدين', icon: 'pi pi-plus',
                    items: [
                        {label: 'القائمة', icon: 'pi pi-list', routerLink: ['/admin/appointment/']}
                        //  {label: 'إضافة', icon: 'pi pi-pencil', routerLink: ['/appointment/add']},
                    ]
                },
            ];
        }
        this.inlineModel = [
            {
                label: 'حسابي', icon: 'pi pi-fw pi-user'
            },
            {
                label: 'الإعدادات', icon: 'pi pi-fw pi-cog'
            }
        ];
    }

    onMenuClick(event) {
        this.app.onMenuClick(event);
    }
}
