import { Injectable } from '@angular/core';
import {
  CheckBox,
  ControlSelectionPlaceHolder,
  DropdownBox,
  EmailBox,
  Field,
  NumericBox,
  TextAreaBox,
  TextBox
} from '../../models/CustomForm';
import {Subject} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class FormDesignerService {

  public controlPlaceHolderList: ControlSelectionPlaceHolder[] = [];
  formControls: any[] = [];
  public selectedCtrl: any = new Field();
  observer = new Subject();
  showHideEditor = false;
  public editorObservable$ = this.observer.asObservable();

  constructor() {
    this.initialize();
  }
  initialize() {

    this.controlPlaceHolderList.push({ id: '1' , name: 'نص', icon: 'fas fa-i-cursor', type: 'textBox', selected : false});
    this.controlPlaceHolderList.push({ id: '2' , name: 'حقل ارقام', icon: 'fas fa-sort-numeric-down', type: 'numericBox', selected : false});
    this.controlPlaceHolderList.push({ id: '3' , name: 'بريد الكتروني', icon: 'fas fa-at', type: 'emailBox', selected : false});
    this.controlPlaceHolderList.push({ id: '4' , name: 'نص طويل', icon: 'fas fa-sort-alpha-down', type: 'textAreaBox', selected : false});
    this.controlPlaceHolderList.push({ id: '5' , name: 'جيك بوكس', icon: 'far fa-check-square', type: 'checkBox', selected : false});
    this.controlPlaceHolderList.push({ id: '6' , name: 'قائمة منسدلة', icon: 'fas fa-prescription-bottle', type: 'dropdownBox', selected : false});

  }
  resetPlaceHolderList() {
    this.controlPlaceHolderList.forEach( item => {
      item.selected = false;
    });
  }

  onSelectPlaceHolderControl( cont: ControlSelectionPlaceHolder) {
    this.controlPlaceHolderList.forEach( item => {
      if ( item.id !== cont.id ) {
        item.selected = false;
      }
    });
    cont.selected = !cont.selected;

  }

  addSelectedControl( ctrlType: string ) {
    const ctrl = this.createControl( ctrlType );
    this.onRealAddControl( ctrl, 50 );
  }

  addSelectedControls() {

    let count = 100;
    this.controlPlaceHolderList.forEach( item => {
      if ( item.selected ) {
        const ctrl = this.createControl( item.type );
        this.onRealAddControl( ctrl, count );
        count = count + 100;
      }
    });
  }

  createControl( controlType: string ) {

    let ctrl = null;
    switch ( controlType ) {
          case 'textBox': {
            ctrl = new TextBox();
            ctrl.maxlength = 64;
            ctrl.label = 'text box';
            ctrl.type = 'textBox';
            ctrl.icon = 'fas fa-i-cursor';
            ctrl.selected = false;
            // this.onRealAddControl( ctrl, 100 );
            break;
          }
          case 'numericBox': {
            ctrl = new NumericBox();
            ctrl.min = 0;
            ctrl.max = 100;
            ctrl.label = 'numeric box';
            ctrl.type = 'numericBox';
            ctrl.icon = 'fas fa-sort-numeric-down';
            ctrl.selected = false;
            // this.onRealAddControl( ctrl, 200 );
            break;
          }
          case 'emailBox': {
            ctrl = new EmailBox();
            ctrl.label = 'email box';
            ctrl.type = 'emailBox';
            ctrl.icon = 'fas fa-at';
            ctrl.selected = false;
            // this.onRealAddControl( ctrl, 300 );
            break;
          }
          case 'textAreaBox': {
            ctrl = new TextAreaBox();
            ctrl.label = 'text area box';
            ctrl.maxlength = 256;
            ctrl.rows = 3;
            ctrl.type = 'textAreaBox';
            ctrl.icon = 'fas fa-sort-alpha-down';
            ctrl.selected = false;
            // this.onRealAddControl( ctrl, 400 );
            break;
          }
          case 'checkBox': {
            ctrl = new CheckBox();
            ctrl.label = 'check box';
            ctrl.type = 'checkBox';
            ctrl.icon = 'far fa-check-square';
            ctrl.selected = false;
            ctrl.defaultValue = false;
            // this.onRealAddControl( ctrl, 400 );
            break;
          }
          case 'dropdownBox': {
            ctrl = new DropdownBox();
            ctrl.label = 'drop down box';
            ctrl.type = 'dropdownBox';
            ctrl.icon = 'fas fa-prescription-bottle';
            ctrl.selected = false;
            ctrl.options = [];
            // this.onRealAddControl( ctrl, 400 );
            break;
          }


    }
    return ctrl;

  }

  onStartEditor( ) {
    this.showHideEditor = true;
    this.observer.next( true );
  }
  onEndEditor( ) {
    this.showHideEditor = false;
  }
  onRealAddControl( item: any, delay: number ) {
    setTimeout( () => {
      item.id = Date.now().toString();
      this.formControls.push( item );
      }, delay );
  }

  onSelectControlBox(ctrl: any) {

    const selected = ctrl.selected;
    this.formControls.forEach( item => { item.selected = false; } );
    ctrl.selected = !selected;
  }
  onDeleteControlBox(ctrl: any) {

    const tmplist: any[] = [];
    this.formControls.forEach( item => {
      if ( item.id !== ctrl.id) {
        tmplist.push(item);
      }
    });
    this.formControls = [];
    tmplist.forEach( item => {
      this.formControls.push( item );
    });

  }

  onControlReorder( srcId: string, destId: string ) {

    const tmpList: any[] = [];
    const srcItem = this.formControls.filter( x => x.id === srcId )[0];

    this.formControls.forEach( item => {
      if ( item.id === destId ) {
        tmpList.push(srcItem);
      }
      if ( item.id !== srcId ) {
        tmpList.push(item);
      }
    });

    this.formControls = tmpList;

  }
}
