import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {GeneralTransfer, PaginationModel, PagingAndFilteringInfo} from '../models/GeneralTransfer';
import {BASE_URL} from '../models/constant';
import {Department} from '../models/Department';
import {Appointment} from '../models/Appointment';
import {Lookups} from '../models/settings';


@Injectable({
  providedIn: 'root'
})
export class ClientAppointmentService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', Pragma: 'no-cache' }),
  };

  constructor(private http: HttpClient ) {}

  async getPlacesTree( ) {
    const resp = await this.http
        .get<any>(
            `${BASE_URL}api/ClientAppointment/getPlacesTree`, this.httpOptions).toPromise();
    return resp;
  }
  async getDepartmentList( ) {
    const resp = await this.http
        .get<Department[]>(
            `${BASE_URL}api/ClientAppointment/GetDepartmentList`, this.httpOptions).toPromise();
    return resp;
  }

  async GetLookupList( ) {
    const resp = await this.http
        .get<Lookups[]>(
            `${BASE_URL}api/ClientAppointment/GetLookupList`, this.httpOptions).toPromise();
    return resp;
  }


  async getList( ) {
    const resp = await this.http
        .get<Appointment[]>(
            `${BASE_URL}api/ClientAppointment`, this.httpOptions).toPromise();
    return resp;
  }

  async get( ItemId: number) {
    const resp = await this.http
        .get<Appointment>(
            `${BASE_URL}api/ClientAppointment/${ItemId}`, this.httpOptions).toPromise();
    return resp;
  }

  async getAppointment( apptNo: string) {
    const resp = await this.http
        .get<Appointment>(
            `${BASE_URL}api/ClientAppointment/getAppt/${apptNo}`, this.httpOptions).toPromise();
    return resp;
  }
  async getWithDetails( ItemId: number) {
    const resp = await this.http
        .get<Appointment>(
            `${BASE_URL}api/ClientAppointment/GetWithDetails/${ItemId}`, this.httpOptions).toPromise();
    return resp;
  }


  async getQRImageCode( ItemId: number) {
    const resp = await this.http
        .get<GeneralTransfer>(
            `${BASE_URL}api/ClientAppointment/GetQRCode/${ItemId}`, this.httpOptions).toPromise();
    return resp;
  }

  async getForDepartment( DepId: number) {
    const cb = Math.round(new Date().getTime() / 1000);
    const resp = await this.http
        .get<Department>(
            `${BASE_URL}api/ClientAppointment/GetForDepartment/${DepId}/${cb}`, this.httpOptions).toPromise();
    return resp;
  }
  /*
  async profile( ) {
    const resp = await this.http
        .get<OperatorInformation>(
            `${BASE_URL}api/department/profile`, this.httpOptions).toPromise();
    return resp;
  }

   */

  async delete( ItemId: number) {
    const resp = await this.http
        .delete<GeneralTransfer>(
            `${BASE_URL}api/ClientAppointment/${ItemId}`, this.httpOptions).toPromise();
    return resp;
  }


  async add( ItemObject: Appointment) {
    const resp = await this.http
        .post<GeneralTransfer>(
            `${BASE_URL}api/ClientAppointment/`, ItemObject, this.httpOptions).toPromise();
    return resp;
  }

/*
  async update( ItemObject: Appointment) {
    const resp = await this.http
        .put<GeneralTransfer>(
            `${BASE_URL}api/ClientAppointment/`, ItemObject, this.httpOptions).toPromise();
    return resp;
  }
*/

  paging( paginginfo: PagingAndFilteringInfo) {

    return this.http.post<PaginationModel>( `${BASE_URL}api/ClientAppointment/paging`, paginginfo, this.httpOptions);
  }
}
