import {Component, OnInit, ViewChild} from '@angular/core';
import {Table} from 'primeng/table';
import {Appointment} from '../models/Appointment';
import {ActivatedRoute, Router} from '@angular/router';
import {AppointmentService} from '../core/appointment.service';
import {PrintService} from '../core/print.service';
import {LazyLoadEvent, MessageService} from 'primeng/api';
import {SystemMessagingService} from '../utilites/system-messaging.service';
import {Department} from '../models/Department';
import {DashbordCard} from '../models/settings';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [MessageService]
})
export class DashboardComponent implements OnInit {

  @ViewChild('subjectTable', { static: true }) sTable: Table;
  en: any;
  appointments: Appointment[] = [];
  totalRecords =  0;
  deleteDisplay = false;
  deleteMessage = 'هل انت متأكد من حذف هذا الموضوع؟';
  selectedId = 0;
  selectedWord = '';
  cols: any[];
  loading: boolean;
  pageSize = 10;
  citizenName = '';
  appointmentNo = '';
  cardWidth = '20%';
  firstload = true;
  cards: DashbordCard[] = [];
  apptDate = undefined; // new Date( Date.now() );

  constructor(private router: Router,
              private route: ActivatedRoute,
              private apiService: AppointmentService,
              public printService: PrintService,
              private messageService: MessageService,
              private msgService: SystemMessagingService) { }

  async ngOnInit() {
    this.loading = true;
    this.setLocale();
    this.getCards();
  }
  async getCards(){

    this.cards = await this.apiService.getDashboardCards();

    this.cardWidth = ( 100 / this.cards.length )  + '%';
    //this.cardWidth = ( 100 / 4 )  + '%';
  }

  GetNameCount( Count: number ){

    return Count > 0 ? Count.toString() : "&nbsp;" ;
  }

  LazyLoading( event: LazyLoadEvent ) {
    this.loading = true;
    if ( !event.sortField ) {
      event.sortField = 'updatedDate';
      event.sortOrder = -1;
    }
    if ( this.firstload ) {
      event.sortOrder = -1;
      this.firstload = false;
    }
    this.pageSize = event.rows;
    const pNo = (event.first + event.rows ) / event.rows;
    this.callDepService( pNo, this.pageSize, event.sortField, event.sortOrder === -1 ? false : true);
    // this.callDepService( pNo, this.pageSize, event.sortField, false);
  }


  callDepService( pId: number, pSize: number, sOrder: string, sDirect: boolean ) {


    const pinfo = { // new PagingAndFilteringInfo();
      index: pId,
      size: pSize,
      isAsc: sDirect,
      orderBy: sOrder,
      fields : this.getSearchFields()
    };

    this.apiService.paging( pinfo ).subscribe( result => {
      this.appointments = result.items;
      this.totalRecords = result.totalItems;

      this.loading = false;
    });
  }



  getSearchFields(): any {

    const fields: any = {};
    if ( this.citizenName !== null && this.citizenName.length > 0  )  {
      fields[ 'citizenName' ] = this.citizenName;
    }
    if ( this.appointmentNo !== null && this.appointmentNo.length > 0  )  {
      fields[ 'appointmentNo' ] = this.appointmentNo;
    }
    if ( this.apptDate !== null && this.apptDate !== undefined  )  {
      fields[ 'apptDate' ] = new Date( this.apptDate );
    }
    return fields;

  }


  showMessage( severity: string, message: string ) {
    this.messageService.clear();
    this.messageService.add({severity, summary: '', detail: message});
  }
  onEdit(rowData: any) {
    console.log( 'selectRow');
    // this.router.navigateByUrl('/department/edit/' + rowData.id );
    const reportData = [rowData.appointmentNo.toString()];
    this.printService.printDocument('booking-extended', reportData);

  }
  onDelete( data: Department ) {
    this.selectedId = data.id;
    this.selectedWord = data.depName;
    this.deleteDisplay = true;
  }


  async onConfirmDelete( e: any ) {

    this.deleteDisplay = false;
    if ( e === false ) {
      return;
    }

    this.messageService.clear();
    this.selectedWord = '';
    const res = await this.apiService.delete( this.selectedId);
    this.showMessage(res.isOK === true ? 'success' : 'error', this.msgService.getMessage ( res.message ));
    this.sTable.first = 0;
    this.callDepService( 1, this.pageSize, 'updatedDate', false);
    this.deleteDisplay = false;
  }


  onSearch() {
    this.callDepService( 1, this.pageSize, 'updatedDate', false);
  }

  setLocale() {
    this.en = {
      firstDayOfWeek: 0,
      dayNames: ["الاحد", "الاثنين", "الثلائاء", "الاربعاء", "الخميس", "الجمعة", "السبت"],
      dayNamesShort: ["الاحد", "الاثنين", "الثلائاء", "الاربعاء", "الخميس", "الجمعة", "السبت"],
      dayNamesMin: ["الاحد", "الاثنين", "الثلائاء", "الاربعاء", "الخميس", "الجمعة", "السبت"],
      monthNames: [ "كانون الثاني","شباط","اذار","نيسان","ايار","حزيران","تموز","اب","ايلول","تشرين الاول","تشرين الثاني","كانون الاول" ],
      monthNamesShort: [ "كانون الثاني","شباط","اذار","نيسان","ايار","حزيران","تموز","اب","ايلول","تشرين الاول","تشرين الثاني","كانون الاول" ],
      today: 'Today',
      clear: 'Clear',
      dateFormat: 'mm/dd/yy',
      weekHeader: 'Wk'
    };


  }

}
