import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {GeneralTransfer, PaginationModel, PagingAndFilteringInfo} from '../models/GeneralTransfer';
import {BASE_URL} from '../models/constant';
import {Department} from '../models/Department';
import {Appointment} from '../models/Appointment';
import {DashbordCard, Lookups} from '../models/settings';


@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient ) {}

  async getDepartmentList( ) {
    const resp = await this.http
        .get<Department[]>(
            `${BASE_URL}api/appointment/GetDepartmentList`, this.httpOptions).toPromise();
    return resp;
  }
  async getDashboardCards( ) {
    const resp = await this.http
        .get<DashbordCard[]>(
            `${BASE_URL}api/appointment/DashboardCards`, this.httpOptions).toPromise();
    return resp;
  }

  async GetLookupList( ) {
    const resp = await this.http
        .get<Lookups[]>(
            `${BASE_URL}api/appointment/GetLookupList`, this.httpOptions).toPromise();
    return resp;
  }


  async getList( ) {
    const resp = await this.http
        .get<Appointment[]>(
            `${BASE_URL}api/appointment`, this.httpOptions).toPromise();
    return resp;
  }

  async get( ItemId: number) {
    const resp = await this.http
        .get<Appointment>(
            `${BASE_URL}api/appointment/${ItemId}`, this.httpOptions).toPromise();
    return resp;
  }

  async getWithDetails( ItemId: number) {
    const resp = await this.http
        .get<Appointment>(
            `${BASE_URL}api/appointment/GetWithDetails/${ItemId}`, this.httpOptions).toPromise();
    return resp;
  }

  async getQRImageCode( ItemId: number) {
    const resp = await this.http
        .get<GeneralTransfer>(
            `${BASE_URL}api/appointment/GetQRCode/${ItemId}`, this.httpOptions).toPromise();
    return resp;
  }

  async getForDepartment( DepId: number) {
    const resp = await this.http
        .get<Array<Date>>(
            `${BASE_URL}api/appointment/GetForDepartment/${DepId}`, this.httpOptions).toPromise();
    return resp;
  }
  /*
  async profile( ) {
    const resp = await this.http
        .get<OperatorInformation>(
            `${BASE_URL}api/department/profile`, this.httpOptions).toPromise();
    return resp;
  }

   */

  async delete( ItemId: number) {
    const resp = await this.http
        .delete<GeneralTransfer>(
            `${BASE_URL}api/appointment/${ItemId}`, this.httpOptions).toPromise();
    return resp;
  }


  async add( ItemObject: Appointment) {
    const resp = await this.http
        .post<GeneralTransfer>(
            `${BASE_URL}api/appointment/`, ItemObject, this.httpOptions).toPromise();
    return resp;
  }


  async update( ItemObject: Appointment) {
    const resp = await this.http
        .put<GeneralTransfer>(
            `${BASE_URL}api/appointment/`, ItemObject, this.httpOptions).toPromise();
    return resp;
  }

  paging( paginginfo: PagingAndFilteringInfo) {

    return this.http.post<PaginationModel>( `${BASE_URL}api/appointment/paging`, paginginfo, this.httpOptions);
  }
}
