export const BASE_URL = '/';


export const SYS_EMAIL_SETTINGS = 'SYS-EMAIL-SETTINGS';
export const SYS_PASS_RESET_TEMPL = 'SYS-PASS-RESET-TEMPL';
export const SYS_APPT_EMAIL_TEMPL = 'SYS-APPT-EMAIL-TEMPL';

export const LOOKUP_CITIZEN_TYPE = 'LOOKUP-CITIZEN-TYPE';
export const LOOKUP_APPLICATION_TYPE = 'LOOKUP-APPLICATION-TYPE';


