import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';



import { AppMainComponent } from './app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {RouteGuardService} from './core/route-guard.service';
import {AppointmentComponent} from './appointment.component';
import {PrintLayoutComponent} from './printing/printLayout.component';
import {AppointmentPrintingComponent} from './printing/appointment.printing.component';
import {AppointmentBookingComponent} from './appointmentbooking.component';
import {AppointmentPrintExtendedComponent} from './printing/appointmentPrintExtended.component';

export const routes: Routes = [

     {path: '', component: AppointmentComponent},

    { path: 'admin', component: AppMainComponent,
        children: [
            { path: 'admin', redirectTo: '/home', pathMatch: 'full' , canActivate: [RouteGuardService]},
            { path: 'home', component: DashboardComponent , canActivate: [RouteGuardService]},
            {
                path: 'operator',
                loadChildren: () => import('./operator/operator.module').then(m => m.OperatorModule ),
                canActivate: [RouteGuardService]
            },
            {
                path: 'settings',
                loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule ),
                canActivate: [RouteGuardService]
            },
           /*
           {
                path: 'department', loadChildren: () => import('./department/department.module').then(m => m.DepartmentModule )
           },
           */
            {
                path: 'appointment', loadChildren: () => import('./appointment/appointment.module').then(m => m.AppointmentModule ),
                canActivate: [RouteGuardService]
            },
            /*{
                path: 'custom-form', loadChildren: () => import('./custom-form/custom-form.module').then(m => m.CustomFormModule )
            }*/
        ]
    },
    {
        path: 'auth',
        loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule )
    },
    {path: 'appt/:apptNo', component: AppointmentBookingComponent},
    {
        path: 'forms', loadChildren: () => import('./custom-form/custom-form.module').then(m => m.CustomFormModule )
    },
    /* {path: 'forms/:formId', component: FormsComponent}, */
    {path: 'error', component: AppErrorComponent},
    {path: '404', component: AppNotfoundComponent},
    {path: '**', redirectTo: '/404'},
    { path: 'print',
        outlet: 'print',
        component: PrintLayoutComponent,
        children: [
            { path: 'booking/:paramIds', component: AppointmentPrintingComponent },
            { path: 'booking-extended/:paramIds', component: AppointmentPrintExtendedComponent },

        ]
    }
];
@NgModule({
    imports: [
        RouterModule.forRoot(routes, { useHash: false})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
