import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

@Component({
    selector: 'app-print-layout',
    templateUrl: './printLayout.component.html'


})
export class PrintLayoutComponent  implements OnInit {

    schoolName = '';
    showHeder = true;
    constructor( private router: Router) {

        // listen to events from Router
        this.router.events.subscribe(event => {
            if ( event instanceof NavigationEnd) {
                // event is an instance of NavigationEnd, get url!
                const url = event.urlAfterRedirects;
                if ( url.indexOf( 'studentPrintCards') !== -1 ) {
                    this.showHeder = false;
                }
                console.log('url is', url);
            }
        });


    }

    async ngOnInit() {

    }

}
