import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {LocationStrategy, PathLocationStrategy} from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import {AuthenticationModule} from './authentication/authentication.module';
import {PrimeNGModule} from './primeng.module';

import { AppComponent } from './app.component';
import { AppCodeModule } from './app.code.component'
import { AppMainComponent } from './app.main.component';
import { AppConfigComponent } from './app.config.component';

import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppMenuComponent } from './app.menu.component';
import { AppMenuitemComponent } from './app.menuitem.component';
import { AppTopBarComponent } from './app.topbar.component';
import { AppFooterComponent } from './app.footer.component';

import { MenuService } from './app.menu.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import {UtilitesModule} from './utilites/utilites.module';
import {CoreModule} from './core/core.module';
import {httpInterceptorProviders} from './Interceptors';
import {SystemMessagingService} from './utilites/system-messaging.service';
import {AppointmentComponent} from './appointment.component';
import {ClientAppointmentService} from './core/clientappointment.service';
import {PrintLayoutComponent} from './printing/printLayout.component';
import {AppointmentPrintingComponent} from './printing/appointment.printing.component';
import {PrintService} from './core/print.service';
import {AppointmentBookingComponent} from './appointmentbooking.component';
import {AppointmentPrintExtendedComponent} from './printing/appointmentPrintExtended.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        AppCodeModule,
        HttpClientModule,
        BrowserAnimationsModule,
        PrimeNGModule,
        CoreModule,
        UtilitesModule,
        AuthenticationModule,
        ProgressSpinnerModule
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppConfigComponent,
        AppTopBarComponent,
        AppFooterComponent,
        AppNotfoundComponent,
        AppErrorComponent,
        DashboardComponent,
        AppointmentComponent,
        PrintLayoutComponent,
        AppointmentPrintExtendedComponent,
        AppointmentPrintingComponent,
        AppointmentBookingComponent


    ],
    providers: [
        {provide: LocationStrategy,  useClass: PathLocationStrategy },
        MenuService, httpInterceptorProviders, SystemMessagingService, ClientAppointmentService, PrintService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
