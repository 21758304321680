import { Pipe, PipeTransform } from '@angular/core';
import * as data from '../../data/captions.json';
import {Caption} from '../../models/caption';

@Pipe({
  name: 'captionPipe',
  pure: false
})
export class CaptionPipe implements PipeTransform {

  captionList: Caption[];
  constructor() {

    // @ts-ignore
    this.captionList = data.default as Caption[];
  }

  transform( captionId: string): string {

    const cap = this.captionList.find( it => it.id === captionId );
    if ( cap ) {
      return cap.ar;
    } else {
      console.warn( `caption ${captionId} not found`);
    }
    return '';
  }
}

