import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ClientAppointmentService} from '../core/clientappointment.service';
import {PrintService} from '../core/print.service';
import {SystemMessagingService} from '../utilites/system-messaging.service';
import {Appointment} from '../models/Appointment';


@Component({
    selector: 'app-print-appointment',
    templateUrl: './appointment.printing.component.html',
    styleUrls: ['./appointment.printing.component.scss']

})
export class AppointmentPrintingComponent  implements OnInit {

    cityName = 'مديرية بلديات محافظة البصرة';
    municipalityName  = 'بلدية سفوان';
    formTitle = 'نظام التقديم والحجز المسبق';

    apptId: string;
    appointmentModel: Appointment = new Appointment();

    constructor(private router: Router,
                private route: ActivatedRoute,
                private appointmentApiService: ClientAppointmentService,
                public printService: PrintService,
                private msgService: SystemMessagingService) {

        this.apptId = route.snapshot.params['paramIds'];

    }

    async ngOnInit() {
        this.appointmentApiService.getAppointment( this.apptId ).then( qrCode => {
            this.appointmentModel = qrCode;
            setTimeout( () => { this.printService.onDataReady(); }, 100 );
        });
    }

}
