import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {GeneralTransfer, PaginationModel, PagingAndFilteringInfo} from '../models/GeneralTransfer';
import {BASE_URL} from '../models/constant';
import {Department} from '../models/Department';


@Injectable({
  providedIn: 'root'
})
export class DepartmentService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient ) {}

  async getList( ) {
    const resp = await this.http
        .get<Department[]>(
            `${BASE_URL}api/department/list`, this.httpOptions).toPromise();
    return resp;
  }

  async get( ItemId: number) {
    const resp = await this.http
        .get<Department>(
            `${BASE_URL}api/department/${ItemId}`, this.httpOptions).toPromise();
    return resp;
  }

  async delete( ItemId: number) {
    const resp = await this.http
        .delete<GeneralTransfer>(
            `${BASE_URL}api/department/${ItemId}`, this.httpOptions).toPromise();
    return resp;
  }


  async add( ItemObject: Department) {
    const resp = await this.http
        .post<GeneralTransfer>(
            `${BASE_URL}api/department/`, ItemObject, this.httpOptions).toPromise();
    return resp;
  }


  async update( ItemObject: Department) {
    const resp = await this.http
        .put<GeneralTransfer>(
            `${BASE_URL}api/department/`, ItemObject, this.httpOptions).toPromise();
    return resp;
  }

  paging( paginginfo: PagingAndFilteringInfo) {

    return this.http.post<PaginationModel>( `${BASE_URL}api/department/paging`, paginginfo, this.httpOptions);
  }
}
