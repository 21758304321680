import {AfterViewInit, Component, ElementRef, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ClientAppointmentService} from '../core/clientappointment.service';
import {PrintService} from '../core/print.service';
import {SystemMessagingService} from '../utilites/system-messaging.service';
import {Appointment} from '../models/Appointment';
import {LocationService} from '../core/location.service';
import {Place} from '../models/Places';


@Component({
    selector: 'app-print-appointment-extended',
    templateUrl: './appointmentPrintExtended.component.html',
    styleUrls: ['./appointmentPrintExtended.component.scss']

})
export class AppointmentPrintExtendedComponent  implements OnInit, AfterViewInit   {

    cityName = 'مديرية بلديات محافظة البصرة';
    municipalityName  = 'بلدية سفوان';
    formTitle = 'معلومات التقديم والحجز المسبق';

    apptId: string;
    appointmentModel: Appointment = new Appointment();
    locations: Place[] = [];

    constructor(private router: Router,
                private route: ActivatedRoute,
                private elementRef: ElementRef,
                private appointmentApiService: ClientAppointmentService,
                private locationApiService: LocationService,
                public printService: PrintService,
                private msgService: SystemMessagingService) {

        this.apptId = route.snapshot.params['paramIds'];

    }
    ngAfterViewInit(){
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#fff';
    }

    async ngOnInit() {
        this.getLocationList();
        this.appointmentApiService.getAppointment( this.apptId ).then( qrCode => {
            this.appointmentModel = qrCode;
            setTimeout( () => { this.printService.onDataReady(); }, 100 );
        });

    }
    async getLocationList(){
        this.locations = await this.locationApiService.getFullList();
    }

    getName( placeId: number ){

        const location = this.locations.find( item => item.id == placeId );
        if ( location ) {
            return location.locName;
        }
        return '';
    }

    getLocationFullName( type: string) {

        let fullLocation = '';
        switch ( type ){
            case 'pod': {
                fullLocation = `${ this.getName(this.appointmentModel.pobCity) } - ${this.getName(this.appointmentModel.pobDistrict)} - ${this.getName(this.appointmentModel.pobLocality)}`;
                break;
            }
            case 'residence': {
                fullLocation = `${this.getName(this.appointmentModel.residenceCity)}`;
                //fullLocation = `${this.getName(this.appointmentModel.residenceCity)} - ${this.getName(this.appointmentModel.residenceDistrict)} - ${this.getName(this.appointmentModel.residenceLocality)}`;
                break;
            }
            case 'employment': {
                fullLocation = `${this.getName(this.appointmentModel.employmentCity)} - ${this.getName(this.appointmentModel.employmentDistrict)} - ${this.getName(this.appointmentModel.employmentLocality)}`;
                break;
            }
            case 'landPlot': {
                fullLocation = `${this.getName(this.appointmentModel.landPlotCity)} - ${this.getName(this.appointmentModel.landPlotDistrict)} - ${this.getName(this.appointmentModel.landPlotLocality)}`;
                break;
            }
            case 'partnerResidence': {
                fullLocation = `${this.getName(this.appointmentModel.partnerResidenceCity)} - ${this.getName(this.appointmentModel.partnerResidenceDistrict)} - ${this.getName(this.appointmentModel.partnerResidenceLocality)}`;
                break;
            }
        }
        return fullLocation;
    }

    getMaritalStatus() {
        switch ( this.appointmentModel.maritalStatus ){
            case 'M' : {
                return 'متزوج(ة)';
                break;
            }
            case 'S' : {
                return 'اعزب / عزباء';
                break;
            }
            case 'D' : {
                return 'مطلق(ة)';
                break;
            }
            case 'W' : {
                return 'ارمل(ة)';
                break;
            }
        }
    }
    getcertificatePart( partNo: number ){

        if ( this.appointmentModel.birthCertificateDetails == undefined ) {
            return '';
        }

        const parts = this.appointmentModel.birthCertificateDetails.split('##');
        return parts[partNo];

    }
}
